import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import ContactForm from "../components/Contact/ContactForm"
import { graphql } from "gatsby"
import Seo from "../components/App/Seo"

const Contact = ({ data }) => {
  const { metaTags } = data
  const { contactUs } = data
  const {
    contactImage: { url: contactImageUrl },
  } = contactUs
  const { metaImage } = metaTags

  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "contact-us"
  })[0]

  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={"contact"}
      />
      <Navbar />
      <PageBanner
        pageTitle="Contact"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Contact"
      />
      <ContactInfo contactUs={contactUs} />
      <ContactForm contactImageUrl={contactImageUrl} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetPageDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        id
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    contactUs: strapiContactUs {
      id
      address
      country
      mobile
      telephone
      email
      contactImage {
        url
      }
      hoursOfOperation {
        timings
      }
    }
  }
`
export default Contact
