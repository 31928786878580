export const removeSpace = {
  regex: /\s+/g
};

export const phone = {
  regex: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  message: "Phone number must be a valid phone number."
};
export const password = {
  regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  message: "Password must: • Be at least 8 characters long • Contain at least one number • Contain at least one lower case letter • Contain at least one upper case letter • Contain at least one non-alphanumeric character."
};
export const alphaNumeric = {
  regex: /[A-Za-z0-9]$/,
  message: "Only letters and numbers are allowed."
};
export const numeric = {
  regex: /[0-9]$/,
  message: "Only numbers are allowed."
};
export const alphabets = {
  regex: /[A-Za-z]$/,
  message: "Only letters are allowed."
};

export const name = {
  regex: /^[a-zA-Z0-9-&_.]*$/,
  message: "Only letters, numbers, periods, ampersand, hyphens and underscores are allowed."
};

export const username = {
  regex: /[A-Za-z0-9_@.-]$/,
  message: "Only letters, numbers, periods, hyphens, @ and underscores are allowed."
};

export const ipAddress = {
  regex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  message: "IP Address must be a valid IP Address of four decimal numbers separated by periods; each number can be written as 0 to 255 (e.g., 0.0.0.0 to 255.255.255.255)."
};