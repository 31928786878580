import axios from "axios"
import { Formik } from "formik"
import React, { useRef, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import starIcon from "../../assets/images/star-icon.png"
import { alphabets, phone } from "../../utils/regex"
import ReCAPTCHA from "react-google-recaptcha"
import { navigate } from "gatsby"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(alphabets.regex, alphabets.message)
    .max(50, "Name must be at most 16 characters")
    .min(2, "Name is too short")
    .required("Please enter name"),
  phone_number: Yup.string()
    .required("Please enter phone number")
    .matches(phone.regex, phone.message)
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number must be at most 15 digits"),
  email: Yup.string()
    .email("The email is incorrect")
    .required("Please enter your email"),
  message: Yup.string()
    .max(5000, "Message must be at most 5000 characters")
    .required("Please enter message"),
})

const ContactForm = ({ contactImageUrl }) => {
  const notifyError = message =>
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    })
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = useRef(null)

  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            Get in Touch
          </span>
          <h2>Ready to Get Started?</h2>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contactImageUrl} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <Formik
                initialValues={{
                  name: "",
                  phone_number: "",
                  email: "",
                  message: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={async (
                  values,
                  { resetForm, setStatus, setErrors, setSubmitting }
                ) => {
                  try {
                    // NOTE: Make API request
                    setIsLoading(true)
                    setStatus({ success: true })
                    setSubmitting(false)

                    const token = recaptchaRef.current.getValue()
                    if (token) {
                      setErrors({})
                    } else {
                      setErrors({
                        submit: "You must confirm you are not a robot",
                      })
                      setIsLoading(false)
                      return
                    }

                    const verifyUrl = `${process.env.GATSBY_API_URL}/google-captcha-site-verify`
                    await axios
                      .post(verifyUrl, { token })
                      .then(res => {
                        if (res?.status === 200) {
                          console.log("Human 👨 👩")
                        } else {
                          console.log("Robot 🤖")
                        }
                      })
                      .catch(error => {
                        console.log(error)
                      })

                    const { name, email, phone_number, message } = values
                    const requestData = {
                      name: name,
                      email: email,
                      phone_number: phone_number,
                      company_name: "Cerebrum",
                      message: message,
                      contacted_website: "cerebrum_digital",
                    }

                    const response = await axios.post(
                      `https://stgps.appsndevs.com/lpcerebrumdigital/api/ContactUs`,
                      requestData
                    )
                    const { status } = response
                    if (status === 200) {
                      resetForm()
                      navigate("/thankyou")
                    } else {
                      notifyError(
                        "Something went wrong, please try again in a few minutes"
                      )
                    }
                    setIsLoading(false)
                    recaptchaRef.current.reset()
                  } catch (e) {
                    const errorMessage =
                      "Something went wrong, please try again in a few minutes"
                    notifyError(errorMessage)
                    setStatus({ success: false })
                    setSubmitting(false)
                    console.log("e", e)
                    // setActive(false)
                    setIsLoading(false)
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                  isValid,
                }) => {
                  return (
                    <>
                      <form id="contactForm" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                              <input
                                id="name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Your name *"
                                autoCorrect="off"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                              />
                              {errors.name && touched.name && (
                                <span className="error-message">
                                  {errors.name}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Your email address *"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <span className="error-message">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="Your phone number *"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone_number}
                              />
                              {errors.phone_number && touched.phone_number && (
                                <span className="error-message">
                                  {errors.phone_number}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                cols="30"
                                rows="6"
                                placeholder="Write your message*"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.message}
                              />
                              {errors.message && touched.message && (
                                <span className="error-message">
                                  {errors.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.GATSBY_GOOGLE_SITE_KEY}
                              onChange={handleChange}
                            />
                            {errors.submit && (
                              <span className="error-message">
                                {errors.submit}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <button
                              type="submit"
                              className="default-btn"
                              disabled={isLoading}
                            >
                              <i className="flaticon-tick"></i>
                              {isLoading ? `Sending...` : `Send Message`}{" "}
                              <span></span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
