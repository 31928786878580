import React from "react"

const ContactInfo = ({ contactUs }) => {
  const { id, address, country, telephone, email, hoursOfOperation } = contactUs
  return (
    <div className="contact-info-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-map"></i>
              </div>
              <div className="icon">
                <i className="bx bx-map"></i>
              </div>
              <h3>Our Address</h3>
              {address} <br /> {country}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <div className="icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <h3>Contact</h3>
              <p>
                Phone: <a href={`tel:${telephone}`}>{telephone}</a>
              </p>
              <p>
                E-mail: <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-time-five"></i>
              </div>
              <div className="icon">
                <i className="bx bx-time-five"></i>
              </div>
              <h3>Hours of Operation</h3>
              {hoursOfOperation.map((hours, index) => {
                return <p key={`${id} - ${index}`}>{hours.timings}</p>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
